import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { Spring } from 'react-spring'

import './layout.css'

class Layout extends Component {
  state = {
    cn: null,
    title: 'Armstrong Mobile',
  }
  render() {
    const children = this.props.children
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
            wordpressWpMedia(title: { eq: "bg" }) {
              source_url
            }
            allWordpressWpMedia {
              edges {
                node {
                  id
                  title
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                {
                  name: 'description',
                  content: 'Setting the Highest Standards for Patient Safety',
                },
                { name: 'keywords', content: 'armstrong, international' },
              ]}
            >
              {this.state.cn ? <html lang="cn" /> : <html lang="en" />}
            </Helmet>
            <div
              className="app-bg"
              style={{
                backgroundImage: `url(${data.wordpressWpMedia.source_url})`,
              }}
            />
            <Spring
              config={{ delay: 100, duration: 600 }}
              from={{ opacity: 0 }}
              to={{ opacity: 1 }}
            >
              {props => (
                <div className="app-wrapper" style={props}>
                  {children}
                </div>
              )}
            </Spring>
          </div>
        )}
      />
    )
  }
}
// const Layout = ({ children }) => (
// )

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
